exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cakes-tsx": () => import("./../../../src/pages/cakes.tsx" /* webpackChunkName: "component---src-pages-cakes-tsx" */),
  "component---src-pages-cupcakes-tsx": () => import("./../../../src/pages/cupcakes.tsx" /* webpackChunkName: "component---src-pages-cupcakes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-index-js": () => import("./../../../src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-order-ordersuccess-js": () => import("./../../../src/pages/order/ordersuccess.js" /* webpackChunkName: "component---src-pages-order-ordersuccess-js" */),
  "component---src-pages-wedding-tsx": () => import("./../../../src/pages/wedding.tsx" /* webpackChunkName: "component---src-pages-wedding-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */)
}

